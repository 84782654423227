
.css-bsct24-MuiButtonBase-root-MuiButton-root.Mui-disabled{
  background-color: #E83A9C !important;
  opacity: 0.5;
  color: #fff !important;
}

.Mui-disabled{
  background-color: #E83A9C !important;
  opacity: 0.5;
  color: #fff !important;
}